import React from "react";
import { graphql } from "gatsby";
import PageWithCmsWrapper from "../../components/shared/page-with-cms-wrapper";

const Solutions = ({
                   path,
                   data: {
                     strapiSolutions: {
                       Title,
                       Content,
                       SeoTitle,
                       MetaDescriptions,
                     },
                   },
                 }) => {

  return (
    <PageWithCmsWrapper
      path={path}
      title={Title}
      content={Content}
      seoProps={{ title: SeoTitle, description: MetaDescriptions}}
    />
  );
};

export default Solutions;

export const query = graphql`
  query SolutionsQuery {
    strapiSolutions {
      Title
      Content
      SeoTitle
      MetaDescriptions
    }
  }
`;
